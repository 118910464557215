export function isInViewport(el, top = 0) {
    const rect = el.getBoundingClientRect();
    return (
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) +
                top &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
}


export function observeElementInViewport(element, callback, offset = '0px') {
    if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        callback(entry.target);
                    }
                });
            },
            {
                root: null, // Obserwujemy względem viewportu
                rootMargin: offset, // Dodajemy margines
                threshold: 0, // Callback uruchomi się, gdy jakakolwiek część elementu pojawi się w widoku
            }
        );

        observer.observe(element);
    } else {
        console.warn('IntersectionObserver nie jest obsługiwany w tej przeglądarce.');
    }
}